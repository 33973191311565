import TableHeader from './header';
import React from 'react';
import Table from 'react-bootstrap/Table';
import "./scss/RightClick.css";
import {ContextMenu, ContextMenuTrigger} from "react-contextmenu";
import MenuItem from './contextmenu/contextMenu';
import Paper from '@material-ui/core/Paper';
import TableBodyarea from './body';
import {useSelector} from "react-redux";

/**
 * PielersTable Class generate a dynamic Table with send Configuration. On under Class build
 * TableHeader, TableBody and the context Menu MenuItem with open right or left mause click
 *
 * @prop {object} Source Configuration Object for Create Tables
 * @namespace TableMaker
 * @see {@link MenuItem}
 * @example
 * <PielersTable Configuration={Configuration} />
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
function TableMaker(Source: any)  {


  const tableBody = Source.Configuration.tableBody.keys;
  const indexID = Source.Configuration.tableBody.dataID;
  const tableBodyType = Source.Configuration.tableBody.type
  const tableHeader = Source.Configuration.tableHeader;
  const response = Source.Configuration.tableBody.data;
  const tableHeaderWidth = Source.Configuration.tableHeaderWidth;
 // const tablecolor = Source.Configuration.tablecolor;
  const Menu = Source.Configuration.menu;
  const Store = useSelector((state: any) => state)

  let menu;

  if(Menu.deactive !== undefined && Menu.deactive === true) {
    menu = (<>
      <Paper>
        <Table>
          {TableHeader(tableHeader, tableHeaderWidth)}
          {TableBodyarea(response, tableBody, Menu, tableBodyType, indexID,Store)}
        </Table>
      </Paper>
    </>)
  } else {
    menu = (<>
      <ContextMenuTrigger id="add_same_id" disable={Menu.menuDisable} holdToDisplay={0}>
        <Paper>
          <Table>
            {TableHeader(tableHeader, tableHeaderWidth)}
            {TableBodyarea(response, tableBody, Menu, tableBodyType, indexID,Store)}
          </Table>
        </Paper>
      </ContextMenuTrigger>
      <ContextMenu className="menu" id="add_same_id"    onShow={(e) => removeTag(  e.detail.data.target )} key={'3_ContextMenu'}>
        {MenuItem(Menu.menuItem, Menu.menuroute, Menu.menuIcons,Menu.menuIconsDetail)}
      </ContextMenu>
    </>)
  }




  return (
    <>
      {menu}
    </>
  );

}

/**
 *
 * @param target
 */
function removeTag(target: any){

  localStorage.removeItem('PagesID');
  localStorage.setItem('PagesID', target.dataset.id);
  localStorage.setItem('Param', target.dataset.param);
  document.querySelectorAll('.hover').forEach(e => e.removeAttribute('class'));
  if(target.id !== undefined) {
    let element = document.getElementById(target.id)?.parentElement?.id
    if (element != null) {
      document.getElementById(element)?.setAttribute('class', 'hover');
    }
  }
}




export default TableMaker;
