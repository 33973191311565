import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import React from 'react';
import {SunEditorConfig} from "../../component/const/SunEditorConfig";
import {apiRoot} from "../../RestFullApi/ApiHook";

function WYSIWYG(data: any, i: number, indi: any = undefined) {


  let value;
  if(data.multiValue !== undefined ) {
    if(data.multiValue.element !== undefined) {
      value = data.multiValue.element[data.multiValue.key];
    } else {
      value = '';
    }
  } else {
    value = data.value;
  }
  //console.log(data)
    const JSONAppend = () => {
        if(data.jsonID !== '') {
            let JsonP = JSON.parse((document.getElementById(data.jsonID) as HTMLInputElement).value);

            //let dataValue = (document.getElementById(data.id) as HTMLInputElement).value;
            let dataValue = (document.getElementsByName(data.id)[0] as HTMLTextAreaElement).value;
            //console.log(dataValue);
            //console.log(JsonP);
            JsonP.item[data.appendJson][data.appendJsonField] = dataValue;

            (document.getElementById(data.jsonID) as HTMLInputElement).value = JSON.stringify(JsonP);

            //console.log(JsonP);
        }
    }

  if (data.data !== undefined && data.hasOwnProperty('data')) {
    if (data.data.hasOwnProperty('lang')) {

      return (
        <div>
          <br />
          <label>{data.label}</label>
          <SunEditor  setDefaultStyle={"font-family: var(--bs-body-font-family); font-size: 16px;"} name={data.name} lang={data.data.lang} defaultValue={value} key={'Sun' + i}  height={data.data.height}
                     setOptions={{

                         "imageWidth": "150px",
                         "imageHeight": "150px",
                         "imageGalleryUrl": apiRoot+"/MediaSunEditor/",
                         "imageGalleryHeader": {
                             "Authorization": "Bearer "+localStorage.getItem('token')
                         },

                         buttonList: SunEditorConfig('complex'),
                       attributesWhitelist:
                     {
                         div:'id|class',
                         p: 'id|class',
                         h: 'id|class',
                         span: 'id|class'
                     },
                         "linkNoPrefix": true,
                     }}
          onChange={() => JSONAppend()}/>
        </div>
      )
  }else {
      return (
        <div>
          <br />
          <label>{data.label}</label>
          <SunEditor setDefaultStyle={"font-family: var(--bs-body-font-family); font-size: 16px;"} name={data.name} defaultValue={value} key={'Sun' + i}
                     setOptions={{
                         "imageWidth": "150px",
                         "imageHeight": "150px",
                         "imageGalleryUrl": apiRoot+"/MediaSunEditor/",
                         "imageGalleryHeader": {
                             "Authorization": "Bearer "+localStorage.getItem('token')
                         },
                         buttonList: SunEditorConfig('complex'),
                       attributesWhitelist:
                         {
                             div:'id|class',
                             p: 'id|class',
                             h: 'id|class',
                             span: 'id|class'
                         },
                         "linkNoPrefix": true,
                     }}
                     onChange={() => JSONAppend()}/>
        </div>
      )
    }} else {
    return (
      <div>
        <br />
        <label>{data.label}</label>
        <SunEditor setDefaultStyle={"font-family: var(--bs-body-font-family); font-size: 16px;"} name={data.name} defaultValue={value} key={'Sun' + i}
                   setOptions={{
                       "imageWidth": "150px",
                       "imageHeight": "150px",
                       "imageGalleryUrl": apiRoot+"/MediaSunEditor/",
                       "imageGalleryHeader": {
                           "Authorization": "Bearer "+localStorage.getItem('token')
                       },
                       buttonList: SunEditorConfig('complex'),
                     attributesWhitelist:
                       {
                           div:'id|class',
                           p: 'id|class',
                           h: 'id|class',
                           span: 'id|class'
                       },
                       "linkNoPrefix": true,
                   }} />
      </div>
    )
  }

}

export default WYSIWYG;
