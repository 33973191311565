import React from 'react';
import FormElements from '../../../form-serilizer/modul'
import configuration from '../configuration/content';
import {MediaMap, newModul, SocialMap, TextMap} from '../../../form-serilizer/modul/newModul';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {isEmptyArray} from 'formik';
import {Accordion} from 'react-bootstrap';
import ElementsContentInterface from "../interface/ElementsContent.interface";
import ElementsContentAppendInterface from "../interface/ElementsContentAppend.interface";
import IntlMessage from "../../const/IntlMessage";

function append(props:ElementsContentAppendInterface) {

  let NewIndex;

  if (isEmptyArray(props.element)) {
    if (props.response.elements !== undefined) {
      NewIndex = (props.response.elements.length);
    } else {
      NewIndex = 0;
    }

    props.setIndex(NewIndex)
  } else {
    NewIndex = props.Index + 1;
    props.setIndex(NewIndex)
  }
  // ok bin raus melde dich gleihc
  let para = newModul(props.discr,NewIndex,props.setdeleteElement,props.element,props.Store,props.galerie,props.galerieItems)
  let allElements = [];
  for(let x=0; x<props.element.length;x++){
    allElements.push(props.element[x]);
  }
  allElements.push(para);
  props.setElement(allElements);

}

function Content(props:ElementsContentInterface){

  const element = props.elements;
  const setElement = props.setElements;
  //const respo = props.response


// This function is triggered when the select changes
  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if(value !== 'false') {

      append({response:props.response, setElement:setElement, discr:value, element:element, Index:props.Index, setIndex:props.setIndex, setdeleteElement:props.setdeleteElement,Store:props.Store,galerie:props.galerie,galerieItems:props.galerieItems})

        let setselect = document.getElementById('newElement');
        if (setselect != null) {
            setselect.getElementsByTagName('option')[0].selected = Boolean(true)
        }
        let setselectMediaMap = document.getElementById('newElementMediaMap');
        if (setselectMediaMap != null) {
            setselectMediaMap.getElementsByTagName('option')[0].selected = Boolean(true)
        }
        let setselectSocialMap = document.getElementById('newElementSocialMap');
        if (setselectSocialMap != null) {
            setselectSocialMap.getElementsByTagName('option')[0].selected = Boolean(true)
        }
    }
  };


  let optionTextMap = [];
  for(let xs=0;xs<TextMap(props.Store).length;xs++) {

    let text: string | JSX.Element = '';
    if(TextMap(props.Store)[xs].value !== 'false') {
      text = TextMap(props.Store)[xs].label
    }
      optionTextMap.push(<><option value={TextMap(props.Store)[xs].value}>{text}</option></>)
  }

    let optionMediaMap = [];
    for(let xs=0;xs<MediaMap(props.Store).length;xs++) {

        let text: string | JSX.Element = '';
        if(MediaMap(props.Store)[xs].value !== 'false') {
            text = MediaMap(props.Store)[xs].label
        }
        optionMediaMap.push(<><option value={MediaMap(props.Store)[xs].value}>{text}</option></>)
    }

    let optionSocialMap = [];
    for(let xs=0;xs<SocialMap(props.Store).length;xs++) {

        let text: string | JSX.Element = '';
        if(SocialMap(props.Store)[xs].value !== 'false') {
            text = SocialMap(props.Store)[xs].label
        }
        optionSocialMap.push(<><option value={SocialMap(props.Store)[xs].value}>{text}</option></>)
    }


  let content;
  if(element !== undefined){
    content = element;
  }


  if(props.response === 'new') {
    return (
        <><>
          {configuration('titel', props.response,true,props.Store)}
          {configuration('description', props.response,true,props.Store)}
          <hr />
          <Accordion id={'elements'}>
            {/*<Draggable>*/}
            {FormElements(configuration('default', props.response,true,props.Store),props.modalShow, props.setModalShow,props.modalData, props.setModalData,false,false,false,false,props.Store,props.galerie,props.galerieItems)}

            {content}
            {/*</Draggable>*/}
          </Accordion>
          <hr />
        </>
            <Row>
          <Col md>
            <FloatingLabel controlId="floatingSelectGrid" label={<IntlMessage Store={props.Store} messageId="common.new_element" />} >
              <Form.Select id={'newElement'} aria-label="Floating label select example" onChange={selectChange}>
                {optionTextMap}
              </Form.Select>
            </FloatingLabel>
          </Col><Col md>
                <FloatingLabel controlId="floatingSelectGrid" label={<IntlMessage Store={props.Store} messageId="common.new_element" />} >
                    <Form.Select id={'newElementMediaMap'} aria-label="Floating label select example" onChange={selectChange}>
                        {optionMediaMap}
                    </Form.Select>
                </FloatingLabel>
            </Col><Col md>
                <FloatingLabel controlId="floatingSelectGrid" label={<IntlMessage Store={props.Store} messageId="common.new_element" />} >
                    <Form.Select id={'newElementSocialMap'} aria-label="Floating label select example" onChange={selectChange}>
                        {optionSocialMap}
                    </Form.Select>
                </FloatingLabel>
            </Col>
            </Row>
        </>
    )
  } else
  if(props.response !== undefined && props.response.hasOwnProperty('elements')) {

    return (
        <><>
          {configuration('titel',props.response,false,props.Store)}
          {configuration('description',props.response,false,props.Store)}
          <hr />
          <Accordion id={'elements'} className="drag-box">

            {FormElements(configuration('default',props.response,false,props.Store),props.modalShow, props.setModalShow,props.modalData, props.setModalData,false,false,false,false,props.Store,props.galerie,props.galerieItems)}
            {content}

          </Accordion>
          <hr />
        </>
            <Row>
          <Col md>
            <FloatingLabel controlId="floatingSelectGrid" label={<IntlMessage Store={props.Store} messageId="common.new_element" />} >
              <Form.Select id={'newElement'} aria-label="Floating label select example" onChange={selectChange}>
                {optionTextMap}
              </Form.Select>
            </FloatingLabel>
          </Col><Col md>
            <FloatingLabel controlId="floatingSelectGrid" label={<IntlMessage Store={props.Store} messageId="common.new_element" />} >
                <Form.Select id={'newElementMediaMap'} aria-label="Floating label select example" onChange={selectChange}>
                    {optionMediaMap}
                </Form.Select>
            </FloatingLabel>
        </Col><Col md>
            <FloatingLabel controlId="floatingSelectGrid" label={<IntlMessage Store={props.Store} messageId="common.new_element" />} >
                <Form.Select id={'newElementSocialMap'} aria-label="Floating label select example" onChange={selectChange}>
                    {optionSocialMap}
                </Form.Select>
            </FloatingLabel>
        </Col>
        </Row>
        </>
    )
  } else {
    return (
        <><>
          {configuration('titel', props.response,true,props.Store)}
          {configuration('description', props.response,true,props.Store)}
          <hr />
          <Accordion id={'elements'}>
            {/*<Draggable>*/}
            {FormElements(configuration('default', props.response,true,props.Store),props.modalShow, props.setModalShow,props.modalData, props.setModalData,false,false,false,false,props.Store,props.galerie,props.galerieItems)}

            {content}
            {/*</Draggable>*/}
          </Accordion>
          <hr />
        </>
            <Row>
          <Col md>
            <FloatingLabel controlId="floatingSelectGrid" label={<IntlMessage Store={props.Store} messageId="common.new_element" />} >
              <Form.Select id={'newElement'} aria-label="Floating label select example" onChange={selectChange}>
                {optionTextMap}
              </Form.Select>
            </FloatingLabel>
          </Col><Col md>
              <FloatingLabel controlId="floatingSelectGrid" label={<IntlMessage Store={props.Store} messageId="common.new_element" />} >
                  <Form.Select id={'newElementMediaMap'} aria-label="Floating label select example" onChange={selectChange}>
                      {optionMediaMap}
                  </Form.Select>
              </FloatingLabel>
        </Col><Col md>
              <FloatingLabel controlId="floatingSelectGrid" label={<IntlMessage Store={props.Store} messageId="common.new_element" />} >
                  <Form.Select id={'newElementSocialMap'} aria-label="Floating label select example" onChange={selectChange}>
                      {optionSocialMap}
                  </Form.Select>
              </FloatingLabel>
          </Col>
        </Row>
        </>
    )
  }
}

export default Content;
