import React from 'react';
import PielersForm from '../../index'
import {Accordion, Tabs,Button} from 'react-bootstrap';
import {base, basesettings} from './base';
import {ElementsHead} from '../../../component/const/ElementsHead';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import Tab from "react-bootstrap/Tab";
import ReactDOM from "react-dom";
import SunEditorBig from "../../../component/const/SunEditorBig";
import {Row} from 'react-bootstrap';
import CopyElements from "../../../component/const/CopyElements";
function accordion(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
              setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
              setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
              content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'input',
      'label': 'Accordion Name',
      'name': 'elements[' + index + '][translation][name]',
      'type': 'text',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.name : '') : ''),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/accordion.html',
    },
    ];

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }

  function JSONNewAppend() {

    let JsonP = JSON.parse((document.getElementById('json' + index) as HTMLInputElement).value);

    console.log(JsonP.item);

    let newPart = {
      'headline': '',
      'content':'',
      'id': null,
      'parent': 0,
      'locale': 'de',
    };

    let tmpItem = [];

    for(let x=0;x<JsonP.item.length;x++) {
      tmpItem.push(JsonP.item[x])
    }
    tmpItem.push(newPart);

    JsonP.item = tmpItem;

    (document.getElementById('json' + index) as HTMLInputElement).value = JSON.stringify(JsonP);

    let conf = [
      {
        'formType': 'multiplyinRow',
        'singelCol':true,
        'singleNew':true,
        'RowId':'accordion_'+(JsonP.item.length -1)+'_'+index,
        'cols': [{ 'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.headline" />,
          'type': 'text',
          'placeholder': IntlMessageValue("de","common.headline",Store),
          'value':  '',
          'jsonID':'json' + index,
          'id':'headline'+(JsonP.item.length -1)+'var_' + index,
          'appendJson': (JsonP.item.length -1),
          'appendJsonField': 'headline',
        },
          SunEditorBig('','content',true,data,
              'content'+(JsonP.item.length -1)+'var_' + index,IntlMessageValue('de', 'common.edit_text',Store),'json' + index,'content'+(JsonP.item.length -1)+'var_' + index,(JsonP.item.length -1),'content'),
          {
            'formType': 'button',
            'label': 'Item entfernen',
            'variants': 'danger',
            'removeID':(JsonP.item.length -1),
            'removeIndex':index,
            'namespace':'accordion_',
            'jsonID':'json' + index,
            'size': {
              'xl': 2,
              'md': 2,
              'sm': 2,
            }
          },
        ]
      }
    ]

    console.log(JsonP);
    let newElement = <>{PielersForm(conf)}</>;
    let temp = document.createElement('div');
    temp.classList.add("col-md-4")
    temp.classList.add("mb-3")
    temp.id = 'accordion_'+(JsonP.item.length -1)+'_'+index
    ReactDOM.render(newElement, temp);
    let ele =  document.getElementById('accordionRow'+index) as HTMLDivElement;
    if(ele !== null) {
      ele.appendChild(temp)
    }
  }

  return (
    <>
        <Accordion.Item eventKey={index} id={'accordion'+index}   style={{marginBottom: '10px'}}>
          {ElementsHead(<IntlMessage Store={Store} messageId="module.accordion" />,'accordion',(newElement === false ? (element.translation !== undefined ? element.translation.de.name : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.accordion" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
          <Accordion.Body>

            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey={"items"+index} title={'Elements'} key={"items"+index} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                <Button variant={'success'} onClick={()=>JSONNewAppend()} className={'mb-3'} >Neuen Item hinzufügen</Button><br />
                <Row id={"accordionRow"+index}>{buildItem(element,index,newElement,data,Store)}</Row>
                {buildJson(element,index,newElement,data,Store)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>
          </Accordion.Body>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.accordion"  Store={Store}/>,Store) : <></>)}
        </Accordion.Item>

    </>
  )
}

export default accordion;

const buildJson = (element:any,index:any,newElement:any,data:any,Store:any ) => {

  let Items = [];
  if (newElement === false) {
    let elementItem = element.translation.de.item;

    let defaultJson = {"item": elementItem}
  Items.push({
    'formType': 'input',
    'label': 'json',
    'id':'json' + index,
    'name': 'elements[' + index + '][translation][ItemString]',
    'type': 'hidden',
    'placeholder': "lang[common.insert_headline]",
    'value': JSON.stringify(defaultJson),
  });

  let newConfiguration = Items;

  return PielersForm(newConfiguration)


} else {
  Items.push({
    'formType': 'input',
    'label': 'json',
    'id':'json' + index,
    'name': 'elements[' + index + '][translation][ItemString]',
    'type': 'hidden',
    'placeholder': "lang[common.insert_headline]",
    'value': '{"item":[]}',
  });

  let newConfiguration = Items;

  return PielersForm(newConfiguration)
}
}

const buildItem = (element:any,index:any,newElement:any,data:any,Store:any ) => {

  let Items = [];
  if (newElement === false) {
    let elementItem = element.translation.de.item;

    //let defaultJson = {"item": elementItem}




    if (elementItem !== undefined) {

      for (let x = 0; x < elementItem.length; x++) {
        Items.push(
            {
              'formType': 'multiplyinRow',
              'RowId':'accordion_'+x+'_'+index,
              'singelCol':true,
              'class':'mb-3',
              'cols': [
                 { 'formType': 'input',
                'label': <IntlMessage Store={Store} messageId="common.headline" />,
                'type': 'text',
                'placeholder': IntlMessageValue("de","common.headline",Store),
                'jsonID':'json' + index,
                'id':'headline'+x+'var_' + index,
                'appendJson': x,
                'appendJsonField': 'headline',
                'value': (newElement === false ? elementItem[x].headline : ''),
              },
                SunEditorBig((newElement === false ? elementItem[x] : ''),'content',newElement,data,
                    'content'+x+'var_' + index,IntlMessageValue('de', 'common.edit_text',Store),'json' + index,'content'+x+'var_' + index,x,'content'),
                {
                  'formType': 'button',
                  'label': 'Item entfernen',
                  'variants': 'danger',
                  'removeID':x,
                  'removeIndex':index,
                  'namespace':'accordion_',
                  'jsonID':'json' + index,
                  'size': {
                    'xl': 2,
                    'md': 2,
                    'sm': 2,
                  }
                },
              ]
            }
        )

      }
    }

      let newConfiguration = Items;

      return PielersForm(newConfiguration)


  } else {
   return;
  }

}
