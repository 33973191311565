import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Button from 'react-bootstrap/Button';
import IntlMessage from "../../component/const/IntlMessage";

function buttonTable(text: string, id: any, index: number, VisitData: any, VisitDataDel: any,Store:any){



  return (
    <><TableCell key={"td"+id+ "_" +index} data-param={VisitData} data-param-del={VisitDataDel}  data-id={id} id={"td"+id+ "_" +index}>
    <Button type={'button'} href={text+'/'+id} variant={'primary'}><IntlMessage Store={Store} messageId="common.edit" /></Button>
    </TableCell>
    </>
  );

}

export default buttonTable;
