import {MemberMenuConfig, MemberPagesConfig} from "./Member";
import {ShippingMenuConfig, ShippingPagesConfig} from "./Shipping";
import {TaxMenuConfig, TaxPagesConfig} from "./Tax";
import {UnitMenuConfig, UnitPagesConfig} from "./Unit";
import {TaxonMenuConfig, TaxonPagesConfig} from "./Taxon";
import {PromoMenuConfig, PromoPagesConfig} from "./promo";
import {LanguageSwitcherValue} from "../shop/core/components/LanguageSwitcher";
import {ConfigMenuConfig, ConfigPagesConfig} from "./config";


export const SettingsRouteConfig = (value:any) => {

    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMS':
            return [
                ...ConfigPagesConfig(value),
                ...MemberPagesConfig(value),
            ]
        case 'CMSwithMatomo':
            return [
                ...ConfigPagesConfig(value),
                ...MemberPagesConfig(value),
            ]
        case 'ProductCatalog':
        case 'ProductCatalogwithMatomo':
            return [
                ...ConfigPagesConfig(value),
                ...MemberPagesConfig(value),
                ...TaxonPagesConfig(value)
            ]
        case 'SHOP':
            if(process.env.REACT_APP_PROMOTIOM === 'True') {
                return [
                    ...ConfigPagesConfig(value),
                    ...PromoPagesConfig(value),
                    ...MemberPagesConfig(value),
                    ...ShippingPagesConfig(value),
                    ...TaxPagesConfig(value),
                    ...UnitPagesConfig(value),
                    ...TaxonPagesConfig(value)
                ]
            } else {
                return [
                    ...ConfigPagesConfig(value),
                    ...MemberPagesConfig(value),
                    ...ShippingPagesConfig(value),
                    ...TaxPagesConfig(value),
                    ...UnitPagesConfig(value),
                    ...TaxonPagesConfig(value)
                ]
            }

        case 'SHOPwithMatomo':
            if(process.env.REACT_APP_PROMOTIOM === 'True') {
                return [
                    ...ConfigPagesConfig(value),
                    ...PromoPagesConfig(value),
                    ...MemberPagesConfig(value),
                    ...ShippingPagesConfig(value),
                    ...TaxPagesConfig(value),
                    ...UnitPagesConfig(value),
                    ...TaxonPagesConfig(value)
                ]
            } else {
                return [
                    ...ConfigPagesConfig(value),
                    ...MemberPagesConfig(value),
                    ...ShippingPagesConfig(value),
                    ...TaxPagesConfig(value),
                    ...UnitPagesConfig(value),
                    ...TaxonPagesConfig(value)
                ]
            }

        default:
            return [
                ...ConfigPagesConfig(value),
                ...PromoPagesConfig(value),
                //...CustomerPagesConfig(value),
                ...MemberPagesConfig(value),
                //...MemberMerchantPagesConfig(value),
                //...SettingsPagesConfig(value),
                //...SettingsMerchantPagesConfig(value),
                ...ShippingPagesConfig(value),
                ...TaxPagesConfig(value),
                ...UnitPagesConfig(value),
                ...TaxonPagesConfig(value)
            ]
    }
}

export const SettingsMenuConfig = (value:any) => {

    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMS':
            return [
                {
                    header:  LanguageSwitcherValue('common.settings'),
                    icon: 'settings',
                    menuitem: [
                        ...MemberMenuConfig(value),
                        ...ConfigMenuConfig(value)
                    ]
                },
            ]
        case 'CMSwithMatomo':
            return [
                {
                    header:  LanguageSwitcherValue('common.settings'),
                    icon: 'settings',
                    menuitem: [
                        ...MemberMenuConfig(value),
                        ...ConfigMenuConfig(value)
                    ]
                },
            ]
        case 'ProductCatalog':
        case 'ProductCatalogwithMatomo':
            return [
                {
                    header:  LanguageSwitcherValue('common.settings'),
                    icon: 'settings',
                    menuitem: [
                        ...MemberMenuConfig(value),
                        ...ConfigMenuConfig(value),
                        ...TaxonMenuConfig(value),

                    ]
                },
            ]
        case 'SHOP':
            if(process.env.REACT_APP_PROMOTIOM === 'True') {
                return [
                    {
                        header:  LanguageSwitcherValue('common.settings'),
                        icon: 'settings',
                        menuitem: [
                            ...PromoMenuConfig(value),
                            ...MemberMenuConfig(value),
                            ...ShippingMenuConfig(value),
                            ...TaxMenuConfig(value),
                            ...UnitMenuConfig(value),
                            ...TaxonMenuConfig(value),
                            ...ConfigMenuConfig(value)
                        ]
                    },
                ]
            } else {
                return [
                    {
                        header:  LanguageSwitcherValue('common.settings'),
                        icon: 'settings',
                        menuitem: [
                            ...MemberMenuConfig(value),
                            ...ShippingMenuConfig(value),
                            ...TaxMenuConfig(value),
                            ...UnitMenuConfig(value),
                            ...TaxonMenuConfig(value),
                            ...ConfigMenuConfig(value)
                        ]
                    },
                ]
            }

        case 'SHOPwithMatomo':
            if(process.env.REACT_APP_PROMOTIOM === 'True') {
                return [
                    {
                        header: LanguageSwitcherValue('common.settings'),
                        icon: 'settings',
                        menuitem: [
                            ...PromoMenuConfig(value),
                            ...MemberMenuConfig(value),
                            ...ShippingMenuConfig(value),
                            ...TaxMenuConfig(value),
                            ...UnitMenuConfig(value),
                            ...TaxonMenuConfig(value),
                            ...ConfigMenuConfig(value)
                        ]
                    },
                ]
            } else {
                return [
                    {
                        header: LanguageSwitcherValue('common.settings'),
                        icon: 'settings',
                        menuitem: [
                            ...MemberMenuConfig(value),
                            ...ShippingMenuConfig(value),
                            ...TaxMenuConfig(value),
                            ...UnitMenuConfig(value),
                            ...TaxonMenuConfig(value),
                            ...ConfigMenuConfig(value)
                        ]
                    },
                ]
            }
        default:
            return [
                {
                    header:  LanguageSwitcherValue('common.settings'),
                    icon: 'settings',
                    menuitem: [
                        ...PromoMenuConfig(value),
                        //...CustomerMenuConfig(value),
                        ...MemberMenuConfig(value),
                        //...MemberMerchantMenuConfig(value),
                        //...SettingMenuConfig(value),
                        //...SettingsMerchantMenuConfig(value),
                        ...ShippingMenuConfig(value),
                        ...TaxMenuConfig(value),
                        ...UnitMenuConfig(value),
                        ...TaxonMenuConfig(value),
                        ...ConfigMenuConfig(value)
                    ]
                },
            ]
    }
    }

