import React from 'react';
import PielersForm from '../../index';
import {Accordion, Tabs} from 'react-bootstrap';
import {base, basesettings} from './base';
import MergeTwoObjecttoArray from '../../../component/const/MergeTwoObjecttoArray';
import {ElementsHead} from '../../../component/const/ElementsHead';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import SunEditorBig from "../../../component/const/SunEditorBig";
import Tab from "react-bootstrap/Tab";
import CopyElements from "../../../component/const/CopyElements";

function image_title(element: any = [], i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                     setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                     setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,content:any = false,setElements:any = [],Store:any,galerie?:any,
                     galerieItems?:any) {

  const index = (newElement === false ? i : newelementIndex);

  const fake_headlines = [1,2,3,4,5,6].map(n => ({
    'label': IntlMessageValue('de',"module.fake_headline_n",Store,"replace", "[n]", n),
    'value': n,
  }));

  const headlines = [1,2,3,4,5,6].map(n => ({
    'label': IntlMessageValue('de',"module.headline_n",Store,"replace", "[n]", n),
    'value': n+6,
  }));

 let options = MergeTwoObjecttoArray(fake_headlines,headlines)

  const configuration = [
    {
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="module.headline_type" />,
      'name': 'elements[' + index + '][headline_type]',
      'legende': <IntlMessage Store={Store} messageId="common.status" />,
      'selected': (newElement === false ? element.headline_type : ''),
      'options': options,
    }, {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.headline" />,
      'name': 'elements[' + index + '][translation][title]',
      'type': 'text',
      'placeholder': "lang[common.insert_headline]",
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.title : '') : ''),
    },
    SunEditorBig((newElement === false ? (element.translation !== undefined ? element.translation.de : '') : ''),'text',newElement,element,'elements[' + index + '][translation][text]',<IntlMessage Store={Store} messageId="module.text" />),
  {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'image',
          'altLink': true,
          'id': 'image_title_image_'+index,
          'path': (newElement === false ? (element.translation !== undefined  ? element.translation.de.media.cdn_url.replace('/templates','http://localhost/templates') : '') : ''),
          'width': '44',
          'height': '44',
          'class': 'elementsIMg',
          'size': {
            'xl': 2,
            'md': 2,
            'sm': 2,
          }
        },
        {
          'formType': 'formFile',
          'onclick': true,
          'id': 'image_titleFormField'+index,
          'onclick_context': (newElement === false ? element.context : 'pielers_element_title_image'),
          'Gallery': galerie,
          'galerieItems': galerieItems,
          'expandfile':index+'ImageTitle',
          'append': 'elements[' + index + '][translation][image_id]',
          'src': 'image_title_image_'+index,
          'experiment': true,
          'size': {
            'xl': 10,
            'md': 10,
            'sm': 10,
          }
        },
      ],
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][translation][image_id]',
      'id': 'elements[' + index + '][translation][image_id]',
      'visible': true,
      'label': 'imageID',
      'type': 'text',
      'placeholder': 'imageID',
      'value': (newElement === false ? (element.translation !== undefined  ? element.translation.de.image_id : '') : ''),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/image_title.html',
    }


  ];
  let newConfiguration = configuration.concat(base(element, i, newElement, newelementIndex));
  const configSettings = [
    {
      'formType': 'select',
      'label': 'Parrallax',
      'name': 'elements['+index+'][translation][parrallax]',
      'selected': (newElement === false ? (element.translation !== undefined ? element.translation.de.parrallax : '') : ''),
      'options': [
        {
          'label': IntlMessageValue('de','common.yes',Store),
          'value': '1',
        },
        {
          'label': IntlMessageValue('de','common.no',Store),
          'value': '0',
        },
      ]

    }
  ]
  let newconfigSettings = configSettings.concat(basesettings(element,i,newElement,newelementIndex,Store));
// 77654
  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
        <Accordion.Item eventKey={index} id={'image_title'+index} key={'image_title'+index}   style={{marginBottom: '10px'}}>
          {ElementsHead(<IntlMessage Store={Store} messageId="module.hero_image" />,'image_title',(newElement === false ? (element.translation !== undefined ? element.translation.de.title : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.hero_image" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
          <Accordion.Body>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newconfigSettings)}
              </Tab>
            </Tabs>
          </Accordion.Body>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.hero_image"  Store={Store}/>,Store) : <></>)}
        </Accordion.Item>

    </>
  );


}

export default image_title;
