import {error} from './error.services';
import {notify_bucket, notify_bucket_error, notify_bucket_reload, notify_del_element} from './notification.service';
import {isEmptyArray} from 'formik';
import IntlMessage, {IntlMessageValue} from './IntlMessage';
import ReactDOM from 'react-dom';
import React from 'react';
import Button from 'react-bootstrap/Button';



export const SendMultiMedia = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, append: any, onclick_context: any, src: any, type: boolean, id: any,url:any,Store:any) => {

  let formfileGlobal = (e.currentTarget as HTMLInputElement).files
  let variant:any = JSON.parse(localStorage.getItem('variant') as string);
  let appendLoader = (e.currentTarget as HTMLElement).parentElement;
  // @ts-ignore
  appendLoader.id = 'Bucketfile';
  var btn = document.createElement("DIV");
  btn.className= 'loaderBucket';
  btn.id= 'loader';
  document.getElementById('Bucketfile')?.appendChild(btn);
  var elem = document.getElementById("loader");
  let option:any = [];
  for(let v = 0; v < variant.length;v++){
    option.push(<option value={variant[v].variantID}>{variant[v].artikelnummer}</option> )
  }
  // @ts-ignore
  for(let x = 0; x < formfileGlobal.length;x++){
    let api_URL: string | undefined;
    if (process.env.REACT_APP_MODE === 'LOCAL') {
      api_URL = process.env.REACT_APP_API_URL_LOCAL;
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
      api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT;
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
      api_URL = process.env.REACT_APP_API_URL_LIVE;
    } else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
      api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD;
    }

    //let token = localStorage.getItem('token');
    // @ts-ignore
    let formfile = formfileGlobal[x]



    //images.profile.path  images.logo.path images.title.path
    let allowedType;
    if(src === 'images.profile.path' || src === 'images.logo.path' || src === 'images.title.path') {
      allowedType = [
        {type:'image/jpg',size: 5000000},
        {type:'image/jpeg', size: 5000000},
        {type:'image/png',size: 5000000}
      ];
    } else {
      allowedType = [
        {type:'image/jpg',size: 5000000},
        {type:'image/jpeg', size: 5000000},
        {type:'image/png',size: 5000000},
        {type:'video/mp4',size: 80000000},
        {type:'video/webm',size: 80000000},
        {type:'application/pdf',size:5000000}
      ];
    }


    let errorCount = 0;
    let errormsg = '';

    let checkType = 0;
    let checkSize = 0;
    if(allowedType !== undefined && allowedType.length > 0) {
      for (let type = 0; type < allowedType.length; type++) {
        if (allowedType[type].type === formfile.type) {
          checkType = checkType + 1;

        }
        if (formfile.size < allowedType[type].size) {
          checkSize = checkSize + 1;
        }

      }
    }

    if(checkType === 0 && checkSize === 0) {
      errorCount = errorCount + 1;
      errormsg = IntlMessageValue('de',"bucket.send.error.checkSize",Store) +(formfile.type === 'video/mp4' ? '25Mb' : '5Mb')+ IntlMessageValue('de',"bucket.send.error.type",Store)+ formfile.type

    } else if(checkType === 0){
      errorCount = errorCount + 1;
      errormsg = IntlMessageValue('de',"bucket.send.error.type",Store)+ formfile.type+'.'

    } else if(checkSize === 0){
      errorCount = errorCount + 1;
      errormsg = IntlMessageValue('de',"bucket.send.error.checkSize",Store) +(formfile.type === 'video/mp4' ? '25Mb' : '5Mb')

    }




    if(errorCount > 0) {
      notify_bucket_error(IntlMessageValue('de',"bucket.send.error",Store),errormsg);
      // @ts-ignore
      elem.remove();
      return;
    }


    let formDatas = new FormData();
    formDatas.append(`file${0}`, formfile);
    formDatas.append("customer", process.env.REACT_APP_CDN_CLIENT as string);
    formDatas.append("context", 'product');

    var requestOptions = {
      method: 'POST',
      body: formDatas,
    };

    fetch(process.env.REACT_APP_CDN_URL+"/api/upload/", requestOptions)
        .then(response => response.text())
        .then(result => {
          if (!type) {
            fetch(api_URL + '/upload/' + onclick_context + '/', {
              method: "POST",
              body: result,
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
              },

            })
                .then(response => response.json())
                .then(data => {
                  let inputValue = (document.getElementById(append) as HTMLInputElement);
                  inputValue.value = data.id;
                  let inputValueSRC = (document.getElementById(src) as HTMLInputElement);
                  inputValueSRC.src = data.cdn_url;
                  notify_bucket(Store);

                })
                .catch(e => {

                  error(e);
                });
          } else if(type) {
            fetch(api_URL + 'upload/' + url + '' + onclick_context + '/' + id + '/', {
              method: "POST",
              body: result,
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
              },

            })
                .then(response => response.json())
                .then(data => {
                  if (url === 'Product') {
                    console.log(data.path.split('.')[data.path.split('.').length - 1])
                    console.log(data.path.split('.'))
                    let images = JSON.parse(localStorage.getItem('images') as string);
                    if (isEmptyArray(images)) {
                      if (data.path.split('.')[data.path.split('.').length - 1] === 'mp4') {
                        videoadd(data)

                      } else if(data.path.split('.')[data.path.split('.').length - 1] === 'pdf'){
                        documentadd(data)

                      } else {
                        imgadd(data,option)

                      }
                    }
                  } else {
                    console.log(data.path.split('.')[data.path.split('.').length - 1])
                    console.log(data.path.split('.'))
                    if (data.path.split('.')[data.path.split('.').length - 1] === 'mp4') {
                      videoadd(data)

                    } else if (data.path.split('.')[data.path.split('.').length - 1] === 'pdf') {
                      documentadd(data)

                    } else {
                      imgadd(data, option)

                    }
                  }
                  notify_bucket_reload(Store);
                  // @ts-ignore
                  elem.remove();

                })
                .catch(e => {
                  error(e);

                });
          }})
        .catch(error => console.log('error', error));
  }
  // @ts-ignore
  setTimeout(() => elem.remove(), 1500);
  setTimeout(() =>  window.location.reload(), 3000);
  e.currentTarget.value = '';

}

const imgadd = (data:any,option:any) => {
  let newElement = (<>
    <img className={'img-thumbnail'} alt={data.id}
         src={(process.env.REACT_APP_MODE === 'LOCAL' ? process.env.REACT_APP_CDN_URL_LOCAL : process.env.REACT_APP_CDN_URL)+data.path} />
    <Button variant={'danger'} type={'button'} style={{width: '100%'}}
            onClick={() => deleteImage(data.id,'media'+data.id)}
    ><IntlMessage Store={null} messageId="common.delete" /></Button>
  </>);
  let temp = document.createElement('col');
  temp.className = 'col-xl-2 col-md-2 col-sm-2'
  temp.id = 'media'+data.id
  temp.style.marginBottom = '10px'
  ReactDOM.render(newElement, temp);
  let ele = document.getElementById('imgBody');
  if (ele !== null) {
    ele.appendChild(temp)
  }
}

const videoadd = (data:any) => {
  let newElement = (<>
    <video width="215" height="140" controls>
      <source src={(process.env.REACT_APP_MODE === 'LOCAL' ? process.env.REACT_APP_CDN_URL_LOCAL : process.env.REACT_APP_CDN_URL)+data.path} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <Button variant={'danger'} type={'button'} style={{width: '100%'}}
            onClick={() => deleteImage(data.id,'mediaVideo'+data.id)}
    ><IntlMessage Store={null} messageId="common.delete" /></Button>
  </>);
  let temp = document.createElement('col');
  temp.className = 'col-xl-4 col-md-4 col-sm-4'
  temp.id = 'mediaVideo'+data.id
  temp.style.marginBottom = '10px'
  ReactDOM.render(newElement, temp);
  let ele = document.getElementById('videoBody');
  if (ele !== null) {
    ele.appendChild(temp)
  }
}

const documentadd = (data:any) => {
  let name = data.path.split('/');
  let newElement = (<>
    <div className={'img-thumbnail'}>
      <span className="material-icons" style={{verticalAlign: '-20%', color: 'red',fontSize: '65px'}}>picture_as_pdf</span><br />
      <a href={(process.env.REACT_APP_MODE === 'LOCAL' ? process.env.REACT_APP_CDN_URL_LOCAL : process.env.REACT_APP_CDN_URL)+data.path}>
        {name[name.length - 1]}
      </a></div>
    <Button variant={'danger'} type={'button'} style={{width: '100%'}}
            onClick={() => deleteImage(data.id,'mediaDocument'+data.id)}
    ><IntlMessage Store={null} messageId="common.delete" /></Button>
  </>);
  let temp = document.createElement('col');
  temp.className = 'col-xl-2 col-md-2 col-sm-2'
  temp.id = 'mediaDocument'+data.id
  temp.style.marginBottom = '10px'
  ReactDOM.render(newElement, temp);
  let ele = document.getElementById('documentBody');
  if (ele !== null) {
    ele.appendChild(temp)
  }
}

/*const saveVariant = (id:any,e:any) => {
  let variant_id = e.target.value;
  let api_URL;
  if (process.env.REACT_APP_MODE === 'LOCAL') {
    api_URL = process.env.REACT_APP_API_URL_LOCAL;
  } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
    api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT;
  } else if (process.env.REACT_APP_MODE === 'LIVE') {
    api_URL = process.env.REACT_APP_API_URL_LIVE;
  }   else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
    api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD;
  }
  var formdata = new FormData();
  formdata.append("variant_id", variant_id);
  let token = localStorage.getItem('token');
  fetch(api_URL + '/Single/images/'+id+'/', {
    method: "Put",
    body: formdata,
    headers: {
      'Authorization': 'Bearer ' + token
    },

  })
    .then(response => response.json())
    .then(data => {
      notify_del_element();
    })
    .catch(e => {
      error(e);
    });

}*/

const deleteImage = (id:any,cointaienrID:any) => {
  let api_URL;
  if (process.env.REACT_APP_MODE === 'LOCAL') {
    api_URL = process.env.REACT_APP_API_URL_LOCAL;
  } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
    api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT;
  } else if (process.env.REACT_APP_MODE === 'LIVE') {
    api_URL = process.env.REACT_APP_API_URL_LIVE;
  }   else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
    api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD;
  }

  //let token = localStorage.getItem('token');
  fetch(api_URL + 'Single/images/'+id+'/', {
    method: "DELETE",
    headers: {
      'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
    },

  })
      .then(response => response.json())
      .then(data => {
        notify_del_element();
        // Identifizieren des Kindknotens
        var element = document.getElementById(cointaienrID);

// Aufruf des Elternknotens, um dann dessen Kindknoten zu löschen
        element?.parentNode?.removeChild(element);
      })
      .catch(e => {
        error(e);
      });

}


/**
 * Sende eine Datei zum Bucket von der AWS und speichert diese
 * @param e
 * @param append
 * @param onclick_context
 * @param src
 * @param type
 * @param id
 * @param url
 * @constructor
 */
const SendImage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, append: any, onclick_context: any, src: any, type: boolean, id: any,url:any,mode:any = '',Store:any) => {
  let api_URL: string | undefined;
  if (process.env.REACT_APP_MODE === 'LOCAL') {
    api_URL = process.env.REACT_APP_API_URL_LOCAL;
  } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
    api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT;
  } else if (process.env.REACT_APP_MODE === 'LIVE') {
    api_URL = process.env.REACT_APP_API_URL_LIVE;
  } else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
    api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD;
  }

  let token = localStorage.getItem('token');
  let appendLoader =(e.currentTarget as HTMLElement).parentElement;
  // @ts-ignore
  appendLoader.id = 'Bucketfile';
  let formfile = (e.currentTarget as HTMLInputElement).files
  var btn = document.createElement("DIV");
  btn.className= 'loaderBucket';
  btn.id= 'loader';
  // @ts-ignore
  document.getElementById('Bucketfile').appendChild(btn);
  var elem = document.getElementById("loader");

  //images.profile.path  images.logo.path images.title.path
  let allowedType;
  /* if(src === 'images.profile.path' || src === 'images.logo.path' || src === 'images.title.path') {*/

  if(mode === 'video') {
    allowedType = [
      {type:'video/mp4',size: 80000000},
      {type:'video/webm',size: 80000000},
    ];
  } else {
    allowedType = [
      {type: 'image/jpg', size: 5000000},
      {type: 'image/jpeg', size: 5000000},
      {type: 'image/png', size: 5000000}
    ];
  }
  /*} else {
     allowedType = [
      {type:'image/jpg',size: 5000000},
      {type:'image/jpeg', size: 5000000},
      {type:'image/png',size: 5000000},
      {type:'video/mp4',size: 25000000},
      {type:'application/pdf',size:5000000}
    ];
  }*/


  let errorCount = 0;
  let errormsg = '';

  let checkType = 0;
  let checkSize = 0;
  if(allowedType !== undefined && allowedType.length > 0) {
    for (let type = 0; type < allowedType.length; type++) {
      // @ts-ignore
      if (allowedType[type].type === formfile[0].type) {
        checkType = checkType + 1;

      }
      // @ts-ignore
      if (formfile[0].size < allowedType[type].size) {
        checkSize = checkSize + 1;
      }

    }
  }

  if(checkType === 0 && checkSize === 0) {
    errorCount = errorCount + 1;
    // @ts-ignore
    errormsg = IntlMessageValue('de',"bucket.send.error.checkSize",Store) +(formfile[0].type === 'video/mp4' ? '50MB' : '5MB')+ IntlMessageValue('de',"bucket.send.error.type")+ formfile[0].type
    // @ts-ignore
    elem.remove();
  } else if(checkType === 0){
    errorCount = errorCount + 1;
    // @ts-ignore
    errormsg = IntlMessageValue('de',"bucket.send.error.type",Store)+ formfile[0].type+'.'
    // @ts-ignore
    elem.remove();
  } else if(checkSize === 0){
    errorCount = errorCount + 1;
    // @ts-ignore
    errormsg = IntlMessageValue('de',"bucket.send.error.checkSize",Store) +(formfile[0].type === 'video/mp4' ? '50MB' : '5MB')
    // @ts-ignore
    elem.remove();
  }




  if(errorCount > 0) {
    notify_bucket_error(IntlMessageValue('de',"bucket.send.error",Store),errormsg);
    return;
  }


  let formDatas = new FormData();
  formDatas.append("customer", process.env.REACT_APP_CDN_CLIENT as string);
  formDatas.append("context", onclick_context);
// @ts-ignore
  for (let x = 0; x < formfile.length; x++) {
    // @ts-ignore
    formDatas.append(`file${x}`, formfile[x]);
  }
  var requestOptions = {
    method: 'POST',
    body: formDatas,
  };

  //let storeimage:any;

  fetch(process.env.REACT_APP_CDN_URL+"/api/upload/", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (!type) {
          fetch(api_URL + 'upload/' + onclick_context + '/', {
            method: "POST",
            body: result,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            },

          })
              .then(response => response.json())
              .then(data => {
                if(mode === 'video') {
                  let inputValue = (document.getElementById(append) as HTMLInputElement);
                  inputValue.value = data.id;
                  console.log(src)
                  let inputValueSRC = (document.getElementById(src) as HTMLVideoElement);
                  console.log(inputValueSRC)
                  let source = inputValueSRC.getElementsByTagName('source');
                  console.log(source)
                  source[0].src = data.cdn_url;
                  console.log(data.cdn_url)
                  inputValueSRC.load();
                } else {
                  let inputValue = (document.getElementById(append) as HTMLInputElement);
                  inputValue.value = data.id;
                  let inputValueSRC = (document.getElementById(src) as HTMLInputElement);
                  inputValueSRC.src = data.cdn_url;
                }
                notify_bucket(Store);
                // @ts-ignore
                elem.remove();
              })
              .catch(e => {
                // @ts-ignore
                elem.remove();
                error(e);
              })
        } else if(type) {
          fetch(api_URL + 'upload/' + url + '' + onclick_context + '/' + id + '/', {
            method: "POST",
            body: result,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },

          })
              .then(response => response.json())
              .then(data => {
                if (url === 'Product') {
                  let images = JSON.parse(localStorage.getItem('images') as string);
                  if (isEmptyArray(images)) {
                    let inputValueSRC = (document.getElementById('productBild') as HTMLInputElement);

                    inputValueSRC.src = (process.env.REACT_APP_MODE === 'LOCAL' ? process.env.REACT_APP_CDN_URL_LOCAL : process.env.REACT_APP_CDN_URL)+'/media/cache/marketplace_product_thumbnail/' + data.path;

                  }
                } else {
                  let inputValueSRC = (document.getElementById(src) as HTMLInputElement);

                  if (onclick_context === 'title' || onclick_context === 'logo' || onclick_context === 'profile') {

                    inputValueSRC.src = (process.env.REACT_APP_MODE === 'LOCAL' ? process.env.REACT_APP_CDN_URL_LOCAL : process.env.REACT_APP_CDN_URL) + data.path;
                  } else {
                    inputValueSRC.src = (process.env.REACT_APP_MODE === 'LOCAL' ? process.env.REACT_APP_CDN_URL_LOCAL : process.env.REACT_APP_CDN_URL) + '/media/cache/marketplace_product_thumbnail/' + data.path;
                  }
                }

                notify_bucket_reload(Store);
                // @ts-ignore
                elem.remove();
                //setTimeout(() => window.location.reload(), 3000);
              })
              .catch(e => {
                error(e);
                // @ts-ignore
                elem.remove();
              })
        }})
      .catch(error => console.log('error', error));

}


export default SendImage;
