import React from 'react';
import TableCell from '@material-ui/core/TableCell';
function customer(text: string, id: any, index: number, data: any, VisitData: any, VisitDataDel: boolean,Store:any){

  let display;

  if(data.customer.isGuest === false) {
    display = text;
  } else {
    display = '[GAST] ' + text;
  }

  return (
    <><TableCell key={"td"+id+ "_" +index} data-param={VisitData} data-param-del={VisitDataDel} data-id={id} id={"td"+id+ "_" +index}><span data-id={id} style={{fontWeight:'bolder'}}>{display}</span></TableCell>
    </>
  );

}

export default customer;
