import handleSubmitFormNewProps from '../interface/handleSubmitFormNew.interface'

/**
 * Handelt den Submit
 * @param event
 * @param updateData
 * @param setValidated
 * @param id
 */
export const handleSubmitForm = (event: {currentTarget: any; preventDefault: () => void; stopPropagation: () => void}, updateData: any, setValidated: (value: (((prevState: boolean) => boolean) | boolean)) => void,
                                 id: any = 0) => {

    const form = event.currentTarget;


    //console.log(form)
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    } else {
        event.preventDefault();
        updateData(false,id,form)
    }
    setValidated(true);
};

export const handleSubmitFormNew = (props:handleSubmitFormNewProps) => {
    const form = props.event.currentTarget;
    if (form.checkValidity() === false) {
        props.event.preventDefault();
        props.event.stopPropagation();
    } else {
        props.event.preventDefault();
        props.updateData(false,props.id,props.currentTutorial,props.setCurrentTutorial,props.NowRoute,props.part,props.Store,form)
    }
    props.setValidated(true);
};
