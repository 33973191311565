import TableCell from "@material-ui/core/TableCell";
import React from "react";
import differenceTwoTime from "../../component/const/differenceTwoTime";


export const timeline = (text: string, data: any, index: number, VisitData: any, VisitDataDel: any,Store:any,Rows:any,i:any) => {

    return (
        <><TableCell key={"td"+i+ "_" +index} data-param={VisitData} data-param-del={VisitDataDel} data-id={i} id={"td"+i+ "_" +index}>
            {differenceTwoTime(data[Rows.split('|')[2]],data[Rows.split('|')[1]]).toFixed(2)} h
        </TableCell>
        </>
    );
}
