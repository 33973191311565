import React from 'react';
import PielersForm from '../../index'
import {Accordion, Tabs} from 'react-bootstrap';
import {base, basesettings} from './base';
import {ElementsHead} from '../../../component/const/ElementsHead';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import SunEditorBig from '../../../component/const/SunEditorBig';
import Tab from "react-bootstrap/Tab";
import CopyElements from "../../../component/const/CopyElements";

function module_duo(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                    setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                    setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                    content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.headline"/>,
      'type': 'text',
      'name': 'elements[' + index + '][translation][headline]',
      'placeholder': IntlMessageValue('de',"common.headline",Store),
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.headline : '') : ''),
    },
    { 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.topline"/>,
      'type': 'text',
      'name': 'elements['+index+'][translation][topline]',
      'placeholder': IntlMessageValue('de',"common.topline",Store),
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.topline : '') : ''),
    },
    SunEditorBig((newElement === false && element.translation !== undefined  ? element.translation.de : ''),'text',newElement,data,'elements[' + index + '][translation][text]',<IntlMessage Store={Store} messageId="module.block_n" preperator="replace" param="[n]" replace={1}/>),
    {
      'formType': 'br',
    },
    {
      'formType': 'fetchselect',
      'local': 'allProducts',
      'valueState': 'id',
      'appendid': 'elements['+index+']',
      'beginn': 'true',
      'start': 0,
      'type': 'Products',
      'onClick': 'true',
      'onClick_id': 'element_'+index+'_Products',
      'labelState': 'name',
      'selected': '',
      'idforward':(newElement === false ? element.products : 0),
      'label': <IntlMessage Store={Store} messageId="category.products.add" />
    },
    {
      'formType': 'holder',
      'ressource': '',
      'type': 'Products',
      'appendid': 'elements['+index+']',
      'cointainerid': 'element_'+index+'_Products',
      'index': 'm11'+index,
      'item': (newElement === false ? element.products : '')
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/m_duo.html',
    },
  ]

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));
  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
        <Accordion.Item eventKey={index} id={'module_duo'+index}   style={{marginBottom: '10px'}}>
          {ElementsHead(<IntlMessage Store={Store} messageId="module.duo"/>,'module_duo',(newElement === false ? (element.translation !== undefined ? element.translation.de.headline : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.duo"/>,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
          <Accordion.Body>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>
          </Accordion.Body>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.duo"  Store={Store}/>,Store) : <></>)}
        </Accordion.Item>

    </>
  )


}

export default module_duo;
