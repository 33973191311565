import {Button} from "react-bootstrap";
import IntlMessage from "../../const/IntlMessage";
import React from "react";
import FormFooterProps from "../interface/FormFooterProps.interface";



const FormFooter = (props:FormFooterProps) => {
    let link;
    if(process.env.REACT_APP_MODE === 'LIVE') {
        link = process.env.REACT_APP_PREVIEW_LINK_LIVE;
    } else if(process.env.REACT_APP_MODE === 'DEVELOPMENT'){
        link = process.env.REACT_APP_PREVIEW_LINK_DEVELOPMENT;
    } else {
        link = process.env.REACT_APP_PREVIEW_LINK_LOCAL;
    }
    return(
        <>
            <div id={'headerMCfooter'}>
                <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => props.history(-1)}><IntlMessage Store={props.Store} messageId="common.back"/></Button>

                <Button variant='success'
                        style={{float: 'right', marginRight: '15px'}}
                        type={'submit'}
                        id={'btn_save'}
                >
                    <IntlMessage Store={props.Store} messageId="common.save" />
                </Button>
                {(props.nonPreview === undefined ? <Button href={link+'/preview/type/'+(props.response !== undefined && props.response.hasOwnProperty('translation') ? props.response.translation.de.slug.replaceAll('/','') : '')+'/'} variant={'info'} target={'_blank'} style={{float: 'right', marginRight: '15px'}}>Preview</Button> : <></>)}
                <br style={{clear: 'both'}} />

            </div>
        </>
    )
}

export default FormFooter;
