import React from 'react';
import PielersForm from '../../index'
import {Accordion, Tabs} from 'react-bootstrap';
import {base, basesettings} from './base';
import {ElementsHead} from '../../../component/const/ElementsHead';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import Tab from "react-bootstrap/Tab";
import CopyElements from "../../../component/const/CopyElements";

function cta(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
             setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
             setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
             content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    { 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.url" />,
      'type': 'text',
      'placeholder': IntlMessageValue('de',"common.url",Store),
      'name': 'elements['+index+'][translation][url]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.url : '' ) : ''),
    },{ 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.button_text" />,
      'type': 'text',
      'max': 30,
      'placeholder': IntlMessageValue('de',"common.button_text",Store),
      'name': 'elements['+index+'][translation][cta_text]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.cta_text : '' ) : '' ),
    },{ 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.button_title" />,
      'type': 'text',
      'placeholder': IntlMessageValue('de',"common.button_title",Store),
      'name': 'elements['+index+'][translation][cta_title]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.cta_title : '' ) : ''),
    },{
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="common.target" />,
      'name': 'elements['+index+'][translation][target]',
      'selected': (newElement === false ? (element.translation !== undefined ? element.translation.de.target  : '' ) : ''),
      'options': [
        {
          'label': IntlMessageValue('de',"common.standard",Store),
          'value': 1
        },
        {
          'label': IntlMessageValue('de',"common.new_window",Store),
          'value': 2
        },
        {
          'label': IntlMessageValue('de',"common.parent_window",Store),
          'value': 3
        },
        {
          'label': IntlMessageValue('de',"common.same_window",Store),
          'value': 4
        }]

    },{ 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.css_class" />,
      'type': 'text',
      'placeholder': IntlMessageValue('de',"common.css_class",Store),
      'name': 'elements['+index+'][Class]',
      'value': (newElement === false ? element.Class : '' ),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/cta.html',
    }]

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));
  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
        <Accordion.Item eventKey={index} id={'cta'+index}   style={{marginBottom: '10px'}}>
          {ElementsHead('CTA','cta',(newElement === false ? (element.translation !== undefined ? element.translation.de.cta_text : '' ) : '' ),Status,index,'elementButton','CTA',
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
          <Accordion.Body>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>
          </Accordion.Body>
          {(newElement === false ? CopyElements(element.id,'CTA',Store) : <></>)}
        </Accordion.Item>

    </>
  )


}

export default cta;

/** TODO Muss in alle Module eingebaut werden ** /
 /*
 <div class="col" style="
 width: 50px;
 max-width: 50px;
 height: 64px;
 ">
 <div style="
 background: green;
 height: 100%;
 min-width: 50px;
 "></div></div>
 */
